<template>

  <div class="w-full h-full xl:py-8 bg-slate-100 transition duration-75">

    <div
        class="xl:h-[calc(100vh-4rem)] xl:min-h-[46rem] max-xl:h-[100vh] xl:w-[1280px] xl:mx-auto md:flex bg-slate-50 xl:shadow xl:rounded-xl xl:border-gray-200 xl:border">
      <u-left class="md:w-[25%]"/>
      <u-right class="md:w-[75%] bg-white rounded-xl border-gray-200 border"/>
    </div>
  </div>

</template>

<script>
import ULeft from "@/components/layout-user/ULeft";
import URight from "@/components/layout-user/URight";

export default {
  components: {ULeft, URight},
}
</script>

<style scoped>
</style>
