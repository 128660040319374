import '@/assets/font.css' //字体
import './index.css' //公用样式
import 'md-editor-v3/lib/style.css';
import 'remixicon/fonts/remixicon.css'//图标
import {createApp} from 'vue';
import App from '@/App.vue';
import {get} from "@/utils/request";
import store from "@/store";
import router from "@/router";

const app = createApp(App);

app.config.globalProperties.$store = store;

import VueLazyload from 'vue-lazyload';//图片懒加载
app.use(VueLazyload, {
    preLoad: 0.8,
    loading: require('@/assets/img/loading.gif'),
    attempt: 1
});
import VueCookies from 'vue3-cookies';

import { toast  } from 'vue3-toastify';
import "vue3-toastify/dist/index.css";

get('/getConfig').then(res => {
    let data = {
        title: res.data["webTitle"],
        subTitle: res.data["webSubTitle"],
        icon: res.data.webIcon,
        logo: res.data.webLogo,
        keywords: res.data.keywords,
        description: res.data.description,
        name: res.data["userName"],
        email: res.data["smtpUser"],
        address: res.data["userAddress"],
        sign: res.data["userSign"],
        copyright: res.data["webCopyright"],
        icp: res.data["webIcp"],
        gongan: res.data["webGongan"],
        skm: res.data.webSkm,
        newsTitle: res.data["webNewsTitle"],
        newsSubTitle: res.data["webNewsSubTitle"],
        custom: res.data["webCustom"]
    }
    store.commit("SET_WEBINFO", data);
    document.querySelector('meta[name="keywords"]').setAttribute('content', data.keywords);
    document.querySelector('meta[name="description"]').setAttribute('content', data.description);
    document.querySelector('link[rel="icon"]').setAttribute('href', data.icon);
    app.use(router);
    app.mount('#app');
});

app.config.globalProperties.$toast = toast;
app.config.globalProperties.$cookies = VueCookies;

app.use(VueCookies);


import {config} from 'md-editor-v3';
import LinkAttr from 'markdown-it-link-attributes';//md新窗口打开链接

config({
    markdownItPlugins(plugins) {
        return [
            ...plugins,
            {
                type: 'linkAttr',
                plugin: LinkAttr,
                options: {
                    attrs: {
                        target: '_blank'
                    }
                }
            }
        ];
    }
});
