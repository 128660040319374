import {createStore} from "vuex";

export default createStore({
    state: {// 定义状态
        homeList: [],//首页列表数据
        newsList: [],//首页新闻数据
        swiperList: [],//首页轮播图数据
        webInfo: {
            title: '',
            subTitle: '',
            icon: '',
            logo: '',
            name: '',
            email: '',
            address: '',
            sign: '',
            copyright: '',
            icp: '',
            gongan: '',
            skm: '',
            newsTitle: '',
            newsSubTitle: '',
            custom: ''
        },
        loadingShow: false
    },
    mutations: {// 定义更改状态的方法
        SET_WEBINFO: (state, data) => {
            state.webInfo = data;
        },
        SET_HOMELIST: (state, data) => {
            state.homeList = data;
        },
        SET_NEWSLIST: (state, data) => {
            state.newsList = data;
        },
        SET_SWIPERLIST: (state, data) => {
            state.swiperList = data;
        },
        SET_LOADINGSHOW: (state, data) => {
            state.loadingShow = data;
        }
    }
});

