<template>

  <div class="px-6 py-8 h-screen border-r-2">

    <div class="w-full text-center text-xl">博客管理系统</div>

    <div class="w-full mt-6 relative h-[calc(100%-4rem)]">

      <div
          v-for="item in menuList"
          :class="item.is ? 'bg-gray-100 text-blue-400' : ''"
          class="p-2 cursor-pointer rounded-md hover:text-blue-400"
          @click="menuClick(item.url)">
        <i :class="item.icon"/>
        <span class="ml-2">{{ item.name }}</span>
      </div>

      <div class="absolute bottom-0">
        <a class="btn btn-primary" href="/" target="_blank">网站</a>
        <button class="btn ml-2" @click="logout">退出登录</button>
      </div>

    </div>

  </div>

</template>

<script>
import {get} from "@/utils/request";

export default {
  name: "ALeft",
  data() {
    return {
      menuList: [
        {name: '工作台', icon: 'ri-dashboard-line', url: '/admin', is: true},
        {name: '编写文章', icon: 'ri-edit-box-line', url: '/admin/writing', is: false},
        {name: '文章管理', icon: 'ri-list-check-2', url: '/admin/content', is: false},
        {name: '分类管理', icon: 'ri-hashtag', url: '/admin/type', is: false},
        {name: '新闻管理', icon: 'ri-news-line', url: '/admin/news', is: false},
        {name: '评论管理', icon: 'ri-message-2-line', url: '/admin/comment', is: false},
        {name: '友链管理', icon: 'ri-links-line', url: '/admin/link', is: false},
        {name: '相册管理', icon: 'ri-image-line', url: '/admin/photo', is: false},
        {name: '网站设置', icon: 'ri-settings-5-line', url: '/admin/setting', is: false},
      ]
    }
  },
  mounted() {
    this.menuMouseleave();
  },
  methods: {
    //点击菜单
    menuClick(url) {
      this.$router.push(url);
    },
    //判断当前菜单
    menuMouseleave() {
      let path = window.location.pathname;
      this.menuList.forEach(item => {
        item.is = item.url === path;
      });
    },
    logout() {
      get('/logout').then(res => {
        if (res.data) {
          window.location.href = '/login';
        }
      })
    }
  },
  watch: {
    // 监听路由对象中的变化
    '$route': {
      handler() {
        this.menuMouseleave();
      }
    }
  }
}
</script>

<style scoped>

</style>
