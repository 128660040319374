<template>

  <div class="w-full relative flex bg-slate-50 transition duration-75 tracking-wider">
    <a-left class="w-[15%] bg-white fixed"/>
    <a-right class="w-[85%] ml-[15%]"/>
  </div>

</template>

<script>
import ALeft from "@/components/layout-admin/ALeft";
import ARight from "@/components/layout-admin/ARight";

export default {
  name: "index",
  components: {ALeft, ARight}
}
</script>

<style scoped>

</style>
