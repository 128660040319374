<template>

  <div class="min-h-screen">
    <router-view class="p-6"/>
  </div>

</template>

<script>
export default {
  name: "ARight"
}
</script>

<style scoped>

</style>
